var instagramModule = (function() {
  var _instagramPostURL = "https://www.instagram.com/p/";
  var _hashtag = "nbclubginza"; // Backup: nbclub
  var _queryHash = "3e7706b09c6184d5eafd8b032dbcf487"; // Maybe update in the future, need to search
  var _fetchDataURL = "https://www.instagram.com/graphql/query/?query_hash="+_queryHash+"&variables={%22tag_name%22:%22"+_hashtag+"%22,%22first%22:25,%22after%22:%22%22}"
  var _limitDescriptionWords = 200;
  var _limitPost = 6; // Set 0 for unlimited
  
  var fetchData = function() {
    return new Promise((resolve, reject) => {
      _fetchDataFromInstagram().then(function(response) {
        resolve(response)
      })
    })
  }
  
  var _fetchDataFromInstagram = function() {
    return new Promise((resolve, reject) => {
      $.get(_fetchDataURL, function(response) {
        var processedData = _processRawData(response.data)
        resolve(processedData)
      })
    })
  }
  
  var _processRawData = function(rawData) {
    var hashtagData = rawData.hashtag;
    var media = hashtagData.edge_hashtag_to_top_posts.edges;
    if (_limitPost != 0) media.splice(_limitPost, media.length);
    var processedData = $.map(media, function(each) {
      var node = each.node
      return {
        id: node.id,
        display_url : node.display_url,
        description: _generateInstagramDescription(node),
        url: _generateInstagramURL(node.shortcode)
      }
    })    
    return processedData;
  }
  
  var _generateInstagramDescription = function(node) {
    var captions = node.edge_media_to_caption.edges;
    if (!captions[0]) return "No description"
    
    var caption = captions[0];
    var description = caption.node.text;
    description = commonModule.textTruncate(description, _limitDescriptionWords, '...')
    return description;
    
  }
  
  var _generateInstagramURL = function(shortCode) {
    return _instagramPostURL+shortCode;
  }
  
  return {
    fetchData: fetchData
  }
})();

var twitterModule = (function(){
  var _hashtag = "#NBclubGinza";
  var _fetchDataURL = "https://api.twitter.com/1.1/search/tweets.json?q="+_hashtag+"&include_entities=true&result_type=recent";
  
  var fetchData = function() {
    return new Promise((resolve, reject) => {
      _fetchDataFromTwitter().then(function(response) {
        resolve(response)
      })
    })
  }
  
  var _fetchDataFromTwitter = function() {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: _fetchDataURL,
        type: 'GET',
        beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer AAAAAAAAAAAAAAAAAAAAAAFjHQEAAAAA%2B5wqaE5FSq9vxOJlzni04UKYV4I%3D9PEJ51zaKIyDLFqpDMeHxr1Twtic1tvyGiuSUt1mmkL9VRhtts');
        },
        data: {},
        success: function (response) { 
          console.log(response)
//           var processedData = _processRawData(response);
//           resolve(processedData)
          
        },
        error: function () { },
      });
//       $.get(_fetchDataURL, function(response) {
//         var processedData = _processRawData(response)
//         resolve(processedData)
//       })
    })
  }
  
  var _processRawData = function(rawData) {
    return new Promise((resolve, reject) => {
    	_extractDataFromHTML(html)
        resolve()
    })
  }
  
  var _extractDataFromHTML = function(html) {
    var tweetContainer = $(html).find("section[role='region']").find("div[aria-label='Timeline: Search timeline']").find('div');
    console.log(tweetContainer)
  }
  
  return {
    fetchData: fetchData
  }
})()


var tagModule = (function() {
  var _componentTemplate = $(".component-templates");
  var _tagTemplate = _componentTemplate.find('.tag-template').clone()
  var _tagContainer = $(".tag-container");
  
  var renderTag = function() {
    _fetchTagData().then(function(tagData) {
      tagData.map(function(tag, index) {
        var tagImage = _generateHTML(tag);
        _tagContainer.append(tagImage)
      })
    })
  }
  
  var _fetchTagData = function() {
    return new Promise((resolve, reject) => {
      instagramModule.fetchData().then(function(data){
        resolve(data)
      })
//       twitterModule.fetchData().then(function(data){
//         resolve(data)
//       })
      
    })
  }
  
  var _generateHTML = function(tagInfo) {
    _tagTemplate.find(".tag-image").css("background-image", "url("+tagInfo.display_url+")")
    _tagTemplate.find(".tag-description").html(tagInfo.description)
    _tagTemplate.find(".instagram-link").attr("href", tagInfo.url)
    return _tagTemplate.html();
  }
  
  return {
    renderTag: renderTag
  }
})()

var commonModule = (function() {
  var textTruncate = function(str, length = 100, ending = '...') {
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  
  return {
    textTruncate: textTruncate
  }
})()

tagModule.renderTag();